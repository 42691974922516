<template>
    <div id="discography">
        <div class="discos">
            <h2>{{ $t('text.discography') }}</h2>
            <div class="row">
                <div  target="_blank" class="disco col-md-4 mt-4 col-6" v-for="(disco, key) in discos" :key="key">
                    <a :href="disco.link"><img :src="disco.img" width="100%" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            discos:[
                {
                    img: require('../assets/discografia/portadas_discos/album_1_sergio_david.jpg'),
                    link: 'https://www.youtube.com/watch?v=s2ixbwB6LXM'
                },
                {
                    img: require('../assets/discografia/portadas_discos/album_2_quimica_vital.jpg'),
                    link: 'https://www.youtube.com/watch?v=9AFnfDLHqNk'
                },
                {
                    img: require('../assets/discografia/portadas_discos/album_3_mata_rica.jpg'),
                    link: 'https://www.youtube.com/watch?v=134HG_a7HWo'
                },
                {
                    img: require('../assets/discografia/portadas_discos/album_4_todo_jesus.jpg'),
                    link: 'https://www.youtube.com/watch?v=uATT_jc5d_s&t=23s'
                },
                {
                    img: require('../assets/discografia/portadas_discos/album_5_robert_coronel.jpg'),
                    link: 'https://www.youtube.com/watch?v=JjrQXwrbW3U'
                },
                {
                    img: require('../assets/discografia/portadas_discos/album_6_luis_mogollon.jpg'),
                    link: 'https://www.youtube.com/watch?v=uATT_jc5d_s&t=23s'
                },
                {
                    img: require('../assets/discografia/portadas_discos/album_7_gilberth_roca.jpg'),
                    link: 'https://www.youtube.com/watch?v=gDiPPdhPmaA'
                },
                {
                    img: require('../assets/discografia/portadas_discos/album_8_mezclao.jpg'),
                    link: 'https://www.youtube.com/watch?v=MdrWNTAjBYM'
                }                   
            ]
        }
    }
}
</script>
<style lang="sass">
    #discography
        background: url('../assets/discografia/bg_discografia.jpg')
        background-size: cover
        min-height: 100vh
        display: flex
        align-items: center
        justify-content: end
        padding: 50px
        h2
            color: #AC885F
            font-weight: 300        
        .discos
            max-width: 400px
</style>