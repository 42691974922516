<template>
    <div id="endorsment">
        <b-container>
            <div class="row">
                <div class="col-md-4 endorsment-title">
                    <h2>{{ $t('text.endorsment') }}</h2>
                </div>
                <div class="col-md-4 endorsment" v-for="(endorsment, key) in endorsments" :key="key">
                    <a :href="endorsment.href" target="_blank">
                        <img :src="endorsment.img1" width="100%" alt="">
                        <p class="text-center mt-2"><img :src="endorsment.img2" width="200px" alt=""></p>
                    </a>

                </div>
            </div>            
        </b-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            endorsments: [
                {
                    img1: require('../assets/endorsment/foto_joyful_noise.jpg'),
                    img2: require('../assets/endorsment/joyful_noise_logo_blanco_v2.png'),
                    href: 'https://www.joyfulnoisedrumcompany.com/'
                },                
                {
                    img1: require('../assets/endorsment/foto_bosphorus.jpg'),
                    img2: require('../assets/endorsment/bosphorus_logo_blanco.png'),
                    href: 'https://www.bosphoruscymbals.com/'
                }         
            ]
        }
    }
}
</script>
<style lang="sass">
    #endorsment
        padding: 50px
        min-height: 10vh
        background: black
        display: flex
        align-items: center
        justify-content: center
        .endorsment-title
            display: flex
            align-items: center
            justify-content: center
        .endorsment
            
</style>