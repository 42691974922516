<template>
  <div id="app">
    <!-- Header -->
    <app-header></app-header>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <!-- Footer -->
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from './components/Header.vue'
import AppFooter from './components/Footer.vue'
export default {
    components: {
      AppHeader,
      AppFooter
    }
}
</script>

<style lang="sass">
  @font-face
    font-family: 'Gabriel Sans'
    src: url('./assets/fonts/gabriel_sans/Gabriel-Sans-Cond-Normal.otf') format('opentype')
    font-weight: 400
  @font-face
    font-family: 'Gabriel Sans'
    src: url('./assets/fonts/gabriel_sans/Gabriel-Sans-Light.otf') format('opentype')
    font-weight: 300    
  body
    font-family: 'Gabriel Sans', sans-serif !important
    font-weight: 300
  .btn-theme
    background: #0483CB !important
    color: white !important
    border-radius: 25px !important
</style>