<template>
    <div id="videography">
        <b-container>
            <div class="content">
                <h2 class="text-center">{{ $t('text.videography') }}</h2>
                <div class="videos">
                    <div class="row">
                        <div class="col-md-3 video" v-for="(video, key) in videos" :key="key">
                            <iframe width="100%" height="200px" :src="video.src" :title="video.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div class="col-md-12 mt-2 text-center">
                            <a target="_blank" href="https://www.youtube.com/channel/UCfJu4FSNZS7J7QuR85SkNLw" class="btn btn-theme"><i class="fa fa-youtube-play"></i> {{ $t('videos.watchYoutube') }}</a>
                        </div>
                    </div>
                </div>
            </div>            
        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            videos: [
                {
                    title: 'Naningo - Jim Riley by Gabriel Fuenmayor (w/Drum Solo)',
                    src: 'https://www.youtube.com/embed/Ol15KlHcbSU'
                },
                {
                    title: 'Drum Pop Shuffle Session',
                    src: 'https://www.youtube.com/embed/1sh2UtpBpwU'
                },
                {
                    title: 'Drum Film Session 2021',
                    src: 'https://www.youtube.com/embed/Onp2sC1rMAQ'
                },
                {
                    title: 'David Garibaldi Tower Of Power - Page One (2017)',
                    src: 'https://www.youtube.com/embed/U9i8dWWBAUc'
                }
            ]
        }
    }
}
</script>

<style lang="sass">
    #videography
        min-height: 100vh
        background: #0b0b0b
        padding: 50px
        display: flex
        align-items: center
        justify-items: center
</style>