import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

let messages = {
    es: {
        contact: {
            one: 'Dejame tu mensaje!',
            two: 'Llena el formulario y déjame tu mensaje.',
            three: 'Te contactare lo más pronto posible.'
        },
        text: {
            bio: 'Biografia',
            discography: 'Discografia',
            endorsment: 'Patrocinios',
            videography: 'Videos',
            gallery: 'Galeria',
            contact: 'Contacto',
            name: 'Nombre',
            email: 'Correo',
            phone: 'Telefono',
            city: 'Ciudad',
            message: 'Mensaje',
            send: 'Enviar'
        },
        videos: {
            watchYoutube: 'Ver en youtube'
        },
        phrases: {
            first: "Música, Amor y servicio a Dios son mi Pasión",
            last: "Me dedico a servir a Dios en agradecimiento por la salvación, el amor, la misericordia, el talento, la música y la fe"
        },
        bio: {
            one: 'Gabriel Fuenmayor nació en Valencia, Venezuela. A los 14 años con ayuda de su familia, obtiene su primera batería y así comienza a tomar clases privadas con: Alejandro Lamas (Baterista de Marcos Vidal), Francisco Polo (Orquesta Sinfónica de Carabobo), Jorge Félix Rodríguez “El cubano” (Conservatorio de Música Carabobo, El Sistema), Andrés Briceño (Director y fundador de la Big Band Simón Bolívar Jazz), Angel Balán Brito (piano), Daniel Criado (Maestría en Jazz performance, Summa Cum Laude en percusión sinfónica).',
            two: 'Junto con su pasión, amor y disciplina Gabriel rápidamente comenzó a trabajar como baterista de sesión, tour y educador. A los 18 años tuvo la oportunidad de trabajar en el TMV (Tecnológico de música Valencia) donde permaneció de 2005 a 2010 como Profesor Titular y Jefe de la Cátedra de Batería. También trabajó como instructor en el Instituto Internacional CanZion de 2012 a 2015.',
            three: 'Realizó eventos bajo el nombre de “Concierto de Estudiantes de Batería” presentado por Gabriel Fuenmayor, (2.015 – 2017 – 2019). Con la finalidad de animar, incentivar y hacer una demostración de los avances académicos - prácticos de sus estudiantes. Cada estudiante requería un mínimo estándar a nivel profesional para poder participar',
            four: 'Formó parte de la banda del reconocido músico y compositor multi-instrumentista de vientos Pedro Eustache, en su gira por Venezuela en el estado Lara y estado Carabobo. Grabó  para el Show de Tv Stugo de Disney Animation, con el compositor venezolano Sandro Morales Santoro residenciado en Los Ángeles. Así como también grabó para “South of Broadway Theater Company” en Charleston (Carolina del Sur).',
            five: 'Tuvo la oportunidad de acompañar en diversas oportunidades a Pablo Gil (saxofonista), Juan Cristóbal Moreno (guitarrista) y Carmelo Medina (Guaco). Participó en diversos festivales de Jazz a nivel nacional y formó parte de algunas bandas, entre ellas Mata Rica, All Jay, Road Jazz, Robert Coronel, UC Jazz, 24 de Junio Big Band, Junko Big Band, Mezclao Group, Rifaat y Valientes Church.',
            six: 'Actualmente es artista de una de las marcas de platillos más importantes a nivel mundial “Bosphorus Cymbals”, así como también de la prestigiosa marca de tambores “Joyful Noise Drum Company”. Gabriel Fuenmayor está dedicado a servir como músico de sesión, tour, Drum-Doctor (afinador de baterías para grabaciones y conciertos), y clases privadas.'
        },
        copyright: 'Todos los derechos reservados',
        footer: {
            one: '¡Al que cree todo le es posible!',
            two: 'Marcos 9:23'
        }        
    },
    en: {
        contact: {
            one: 'Leave me a message',
            two: 'Fill out the form and leave me your message.',
            three: 'I will contact you as soon as possible'
        },        
        text: {
            bio: 'Biography',
            discography: 'Discography',
            endorsment: 'Endorsements',
            videography: 'Videos',
            gallery: 'Gallery',
            contact: 'Contact',
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            city: 'City',
            message: 'Message',
            send: 'Send'            
        },
        videos: {
            watchYoutube: 'Watch on youtube'
        },
        phrases: {
            first: "Music, Love and service to God are my Passion",
            last: "I am dedicated to serving God in gratitude for salvation, love, mercy, talent, music and faith"
        },
        bio: {
            one: 'Born and raised in Valencia, Venezuela, Gabriel Fuenmayor purchased his first drum set, with the help of his family, at the age of 14. He immediately began taking private drum lessons with renown teachers such as Alejandro Lamas (drummer and percussionist for Marcos Vidal), Francisco Polo (Carabobo Symphony Orquestra), Jorge Félix Rodriguez (Carabobo Music Conservatory, El Sistema), Andrés Briceño (director/founder of the Simon Bolivar Big Band Jazz), Daniel Criado (jazz master in New York) and Angel Balán Brito (pianist).',
            two: 'Having started his musical career in the high school marching band, along with his passion, love and discipline, Gabriel quickly started working as a session drummer, performer and educator. At age of 18 he was given the opportunity to work at the TMV (Valencia Music Technology) where he remained from 2005 to 2010 as Titular Professor Music Chair from. He also worked as an instructor at the CanZion International Institute from 2012 to 2015.',
            three: 'He held events under the name of "Concert of Drum Students presented by Gabriel Fuenmayor”, (2.015 - 2017 - 2019), in order to encourage, incentivize and to demonstrate the practical/academic progress of his students. Each student required a minimum professional level standard to participate.',
            four: 'Gabriel has performed and toured with well-known artists such as Pedro Eustache, Pablo Gil, Juan Cristobal Moreno, Carmelo Medina, Road Jazz Band, UC Jazz, June 24 Big Band, Junko Quintet, Junko Big Band, Robert Coronel, Mezclao Group, All Jay, Mata Rica, Rifaat, and Valientes Church.',
            five: 'He has also worked as session drummer, recording for "South of Broadway Theater company" in Charleston (South Carolina), and the Disney Animations “Stugo “TV Show.',
            six: 'Gabriel Fuenmayor is a signed artist of one of the most important cymbal brands worldwide "Bosphorus Cymbals", as well as with the prestigious drum brand "Joyful Noise Drum Company". Gabriel Fuenmayor is dedicated to serving as session drummer, tour, Drum-Doctor (drum-tuner for recordings and concerts), and private lessons. '
        },
        copyright: 'All rights reserved',
        footer: {
            one: 'All things are possible to him who believes',
            two: 'Mark 9:23 '
        }

    }
}


export const i18n = new VueI18n({
    locale: window.navigator.language.split('-')[0],
    fallbackLocale: 'en',
    messages
})