<template>
  <div class="home">
    <!-- Hero -->
    <hero />
    <!-- FirstMessage -->
    <message :mode="1" />
    <!-- Biography -->
    <biography />
    <!-- Discography -->
    <discography />
    <!-- Endorsment -->
    <endorsment />
    <!-- Videography -->
    <videography />
    <!-- Gallery -->
    <gallery />
    <!-- Contact -->
    <contact />
    <!-- LastMessage -->
    <message :mode="2" />
  </div>
</template>

<script>
import Hero from "../components/Hero.vue";
import Message from "@/components/FirstMessage.vue";
import Biography from "@/components/Biography.vue";
import Discography from "@/components/Discography.vue";
import Endorsment from "@/components/Endorsment.vue";
import Videography from "../components/Videography.vue";
import Gallery from "@/components/Gallery.vue";
import Contact from "@/components/Contact.vue";
export default {
  name: "Home",
  components: {
    Hero,
    Message,
    Biography,
    Discography,
    Endorsment,
    Videography,
    Gallery,
    Contact,
  },
};
</script>

<style lang="sass">
h2
  color: #AC885F
  font-weight: 300 !important
  .btn-theme
    border-radius: 25px !important
    background: #0483CB !important
    color: white !important
    padding-left: 20px !important
    padding-right: 20px !important
</style>