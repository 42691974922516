<template>
  <b-carousel
    id="carousel-fade"
    style="text-shadow: 0px 0px 2px #000"
    fade
    indicators
    controls
    img-width="1920"
    img-height="1080"
  >
    <b-carousel-slide
      :img-src="oneSlide()"
    ></b-carousel-slide>
    <b-carousel-slide
        :img-src="require('@/assets/slider/siide_2.jpg')"
    ></b-carousel-slide>
    <b-carousel-slide
        :img-src="require('@/assets/slider/slide_3.jpg')"
    ></b-carousel-slide>
    <b-carousel-slide
        :img-src="require('@/assets/slider/slide_4.jpg')"
    ></b-carousel-slide>    
  </b-carousel>    
</template>

<script>
import { i18n } from '@/plugins/i18n.js'
export default {
  data() {
    return {
      oneSlide() 
      {
        if(i18n.locale == 'es')
        {
            return require('@/assets/slider/slide_1_texto_espanol.jpg')
        }else {
            return require('@/assets/slider/slide_1_texto_ingles.jpg')
        }
      }
    }
  }
}
</script>